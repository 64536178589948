import styles from './Titlename.module.scss';

export const Titlename = () => {

return (
    <div className={styles.Titlename}>
        <h1>Romain Bories</h1>
        <h2>Développeur web junior Full-Stack</h2>
    </div>
);


};